<template>
  <div class="warning-box">
    <div class="tabLst-title"><span class="tabLst-title-span" @click="addClickGreenHouse">+添加大棚</span></div>
    <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="600px" height="374px" style="">
      <addgreenhouse :optionList="optionList" :changeList="changeList" @rowList="addtabList"
                     @rowChangeList="changeListRow"></addgreenhouse>
    </el-dialog>
    <el-table
        :data="tableData"
        :stripe="true"
        border
        style="width: 100%"
        :row-class-name="tableRowClassName">
      <el-table-column
          prop="name"
          label="大棚名称">
      </el-table-column>
      <el-table-column
          prop="id"
          label="ID">
      </el-table-column>
      <el-table-column
          prop="area"
          label="面积">
      </el-table-column>
      <el-table-column
          prop="crop"
          label="种植产品">
      </el-table-column>
      <el-table-column
          prop="admin"
          label="区块管理员">
      </el-table-column>
      <el-table-column

          label="操作">
        <template slot-scope="scope">
          <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">编辑
          </el-button>

          <el-popconfirm style="margin-left: 10px"
                         title="确定删除吗？"
                         @confirm="handleDelete(scope.$index, scope.row)"
          >
            <el-button
                size="mini"
                type="danger"
                slot="reference"
            >删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import addgreenhouse from '@/components/addgreenhouse'

export default {
  components: {
    addgreenhouse
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      optionList: [],
      value1: '',
      changeList: {}
    }
  },
  mounted() {
    this.$api.post('gHouse/getByBid', {}, res => {
      console.log(res, '123')
      this.tableData = res.data
    });
    this.$api.post('prod/getPros', {pid: '0'}, res => {
      console.log(res, '123options')
      this.optionList = res.data
    })
  },
  methods: {
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },

    changeListRow(data) {  //修改替换
      console.log(data)
      let idx = this.tableData.findIndex((item) => item.id === data.id)
      console.log(idx)
      this.tableData[idx] = data
      this.dialogVisible = false
    },
    addClickGreenHouse() {  //添加大棚按钮
      this.dialogVisible = true
      this.changeList = {}
    },
    handleEdit(index, row) {  // 修改按钮
      this.changeList= []
      console.log(index, row.id);
      this.dialogVisible = true
      let idx = this.tableData.findIndex((item) => item.id === row.id)
      console.log(idx, 'idx')
      this.changeList = this.tableData[idx]
      console.log(this.changeList, 'this.changeList')
    },
    handleDelete(index, row) {  //删除按钮
      console.log(index, row);
      this.$api.post('gHouse/delById', {id: row.id}, res => {
        console.log(res, '删除', this.tableData)
        let idx = this.tableData.findIndex((item) => item.id === row.id)
        console.log(idx)
        this.tableData.splice(idx, 1)
      })
    },
    addtabList(data) {  //子组件静默添加列表数据
      console.log(data)
      this.tableData.push(data)
      this.dialogVisible = false
    }
  },

}
</script>

<style>

.tabLst-title {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #1A99F2;
  cursor: pointer;
  margin-bottom: 10px;
}

.warning-box {
  background: #FFFFFF;
  margin-top: 20px;
  padding: 30px 36px;
  min-height: 85vh;
}

</style>
